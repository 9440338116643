import React, { useState,useEffect } from "react";
import logo from "./assets/img/logo.png";
import iconPassword from "./assets/img/icon-pass.png";
import AlertModal from "../alertModal/AlertModal";
import { useNavigate  } from "react-router-dom";
import  serviceApi from "../../services/services";
import { Navigate } from "react-router-dom";
import "./cambiarClave.css";
const CambiarClave=()=>{
	const [show, setShow] = useState(false);
	const [show2, setShow2] = useState(false);
	const [passwordType, setPasswordType] = useState("password");
	const [passwordType2, setPasswordType2] = useState("password");

	const [password, cambiarPassword] = useState({ campo: "", valido: null });
	const [password2, cambiarPassword2] = useState({ campo: "", valido: null });

  const [validationUserPass,setValidationUserPass] = useState([false,false,false]);
  const [validationClavePass,setValidationClavePass] = useState([false,false,false,false]);
  const [sending,setSending] = useState(false);
  const [msjCorreo,setMsjCorreo]=useState({});
  const [msjUsuario,setMsjUsuario]=useState({});
  const [msjClave,setMsjClave]=useState({});
  const [msjClave2,setMsjClave2]=useState({});
  const [msjError,setMsjError]=useState({});
  const [mensaje,setMensaje] = useState({});
  const [modal,setModal] = useState({show:false});
  const [claveCambiada,setClaveCambiada] = useState(false);
  const navigate  = useNavigate();
  const [validatePassword, setValidatePassword] = useState({
    large: false,
    mayuscula: false,
    number: false,
    especial: false,
  });

  const expresiones = {
    passwordLarge: /^.{8,20}$/,
    passwordMayuscula: /[A-Z]/,
    passwordNumber: /[0-9]/,
    passwordEspecial: /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/,
    userLarge: /^.{4,12}$/,
    userMayuscula: /[A-Z]/,
    userNumber: /[0-9]/,
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const togglePassword2 = () => {
    if (passwordType2 === "password") {
      setPasswordType2("text");
      return;
    }
    setPasswordType2("password");
  };

  const onChangePassword = async (e) => {
    cambiarPassword({
      campo: e.target.value,
      valido: e.target.value,
    });
  };

  const onChangePassword2 = async (e) => {
    cambiarPassword2({
      campo: e.target.value,
      valido: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/.test(e.target.value),
    });
  };

  const validarPassword2 = () => {
    if (password.campo.length > 0) {
      if (password.campo !== password2.campo) {
        cambiarPassword2((prevState) => {
          return { ...prevState, valido: "false" };
        });
       
      } else {
        cambiarPassword2((prevState) => {
          return { ...prevState, valido: "true" };
        });
       
      }
    }
  };
  const validacion = () => {
    var current_pass = validationClavePass;
    if (expresiones) {
      if (expresiones.passwordLarge.test(password.campo)) {
        console.log("largo valido");
        setValidatePassword({
          ...validatePassword,
          large: true,
        });
        current_pass[0]=true;
      } else {
        setValidatePassword({
          ...validatePassword,
          large: false,
        });
        console.log("largo invalida");
        current_pass[0]=false;
      }
      if (expresiones.passwordMayuscula.test(password.campo)) {
        console.log("mayuscula valida");
        setValidatePassword({
          ...validatePassword,
          mayuscula: true,
        });
        current_pass[1]=true;
      } else {
        setValidatePassword({
          ...validatePassword,
          mayuscula: false,
        });
        console.log("mayuscula invalida");
        current_pass[1]=false;
      }
      if (expresiones.passwordNumber.test(password.campo)) {
        console.log("numero valido");
        setValidatePassword({
          ...validatePassword,
          number: true,
        });
        current_pass[2]=true;
      } else {
        setValidatePassword({
          ...validatePassword,
          number: false,
        });
        console.log("numero invalido");
        current_pass[2]=false;
      }

      if (expresiones.passwordEspecial.test(password.campo)) {
        console.log("especial valido");
        setValidatePassword({
          ...validatePassword,
          especial: true,
        });
        setShow(false);
        current_pass[3]=true;
      } else {
        setValidatePassword({
          ...validatePassword,
          especial: false,
        });
        console.log("especial invalido");
        setShow(true);
        current_pass[3]=false;
      }
    }
    setValidationClavePass(current_pass);
    console.log(validationClavePass)
  };

  const submit = (e)=>{
    e.preventDefault();
    let formData={
     
      clave:password.campo,
      clave2:password2.campo
    }
    setMensaje({});
    setMsjClave2({});
    serviceApi.post("usuario/confirmar_clave",formData).then(function(response){
      if(response.data.code!=200){
        if(response.data.data && response.data.data.clave_error==1){
          setMsjClave2({
            show:true,
            message:response.data.msj
          });
        }else{
          setMensaje({
            show:true,
            message:response.data.msj
          });
        }
       
      }else{
        setClaveCambiada(true);
        setModal({
          show:true,
          message:"Clave actualizada con éxito"
        })
      }
    });
    return false;
  }
  const closeModal=(val)=>{
    setModal({show:val});
  }
  useEffect(()=>{
    if(claveCambiada && modal.show==false){
      navigate("/home");
    }
  },[modal]);
	return(
		<div className="login-body p-0 m-0">
			<div className="d-flex flex-column justify-content-center align-items-center mt-4 login-header">
			<img className="" src={logo} alt="logo" />
			<p className="txt-login mt-2">
				Bienvenido a la aplicación de Sistema de gestión ATMs y Kioskos
			</p>

			</div>
			<br />
			
			<form action="" method="post" onSubmit={submit}>
			<div className="container-inputs mt-4">
        <div className="container-input-title">
          <img className="" src={iconPassword} alt="icon-user" />
          <p className="txt-user">Nueva Clave</p>
        </div>
        <input
          className="input-user w-100"
          type={passwordType}
          value={password.campo}
          onChange={onChangePassword}
          onKeyUp={validacion}
          onFocus={validacion}
          valido={password.valido}
          required
        />
        {
          password.campo!="" && (
            <button type="button" className="btn-show-pass" onClick={togglePassword}>
              {passwordType === "password" ? (
                <i className="fa-light fa-eye"></i>
              ) : (
                <i className="fa-light fa-eye-slash"></i>
              )}
            </button>
          )
        }
        
        {msjClave.show && <p className="txt-error">{msjClave.message}</p>}
        {show && (
          <div className="container-checkbox mt-3">
            <p className="txt-checkbox-title">Debe contener al menos:</p>
            <div className="container-check">
              <input className="check-default" type="checkbox" checked={validationClavePass[0]} />
              <p className="txt-validate">Entre 8 a 20 carácteres</p>
            </div>
            <div className="container-check" >
              <input className="check-default" type="checkbox" checked={validationClavePass[1]}/>
              <p className="txt-validate">1 mayúscula</p>
            </div>
            <div className="container-check" >
              <input className="check-default" type="checkbox" checked={validationClavePass[2]} />
              <p className="txt-validate">1 número</p>
            </div>
            <div className="container-check" >
              <input className="check-default" type="checkbox" checked={validationClavePass[3]} />
              <p className="txt-validate">1 Símbolo ñ&*_!&#@-+:¿¡</p>
            </div>
          </div>
        )}
      </div>
      <div className="container-inputs mt-4">
        <div className="container-input-title">
          <img className="" src={iconPassword} alt="icon-user" />
          <p className="txt-user">Confirmar clave</p>
        </div>
        <input
          className="input-user w-100"
          type={passwordType2}
          onChange={onChangePassword2}
          value={password2.campo}
          onKeyUp={validarPassword2}
          onBlur={validarPassword2}
          valido={password2.valido}
          required
        />
        {
          password2.campo!="" && (
            <button  type="button" className="btn-show-pass" onClick={togglePassword2}>
              {passwordType2 === "password" ? (
                <i className="fa-light fa-eye"></i>
              ) : (
                <i className="fa-light fa-eye-slash"></i>
              )}
            </button>
          )
        }
        
        {msjClave2.show && <p className="txt-error text-left">{msjClave2.message}</p>}
      </div>
      <div className="d-flex flex-column justify-content-center align-items-center my-4">
        <button className={"btn-login mt-5 "+(sending && "btn-loading ")} disabled={sending} >Continuar</button>

        {mensaje.show?(<label className="lbl-error">{mensaje.message}</label>):""}
      </div>
			</form>
      <AlertModal show={modal.show} setShow={closeModal} error={false} message={modal.message}></AlertModal>
		</div>
  

	)
}
export default CambiarClave;
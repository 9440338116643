import React, { useState, useEffect } from "react";
import logo from "./assets/img/logo.svg";
import Navbar from "../Navbar/Navbar";
 

const Header = (props) => {

	const [fecha,setFecha]= useState("");
	const separator = " de ";
	const build_fecha=()=>{
		let meses=['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'];
		let dias=['Domingo','Lunes','Martes','Miercoles','Jueves','Viernes','Sábado'];
		let newDate = new Date()
		let date = newDate.getDate();
		let month = newDate.getMonth();
		let year = newDate.getFullYear();
		let day = newDate.getDay();
		let hora = (newDate.getHours()<10?"0"+newDate.getHours():newDate.getHours())+":"+
		(newDate.getMinutes()<10?"0"+ newDate.getMinutes():newDate.getMinutes());
		
		return `${dias[day]} ${date}${separator}${meses[month]}${separator}${year} - ${hora}`
	}
	
	const [showBack,setShowBack] = useState(false);
	
	
	useEffect(()=>{
		setFecha(build_fecha());
		setInterval(()=>{
			setFecha(build_fecha());
		  },1000);
	  }, [])
	  


	return (
		<>
		<div className="header">
			<div className="d-flex flex-column justify-content-center align-items-center mt-4 login-header">
				<img className="" src={logo} alt="logo" />
			</div>
			<Navbar showBack={props.showBack} callback={props.callback} />
			<div className="d-flex justify-content-center">
				<p className="date-app">{fecha}</p>
			</div>
	 	</div>
		</>
	);
}
export default Header;
import React, { useState,useEffect } from "react";
 
import "./splash.css";
 

import logo_ba from "../../globalassets/img/logo-ba-full.svg"

function Splash(props) {
const [show,setShow] = useState(false);

useEffect(()=>{
	
	if(window.location.pathname=="/"){
		setShow(true);
	setTimeout(function(){
		setShow(false);
	},3000);
	}
	
},[])

  return (
    <>
	{show && (<div className="splash-screen">
		<div className="splash-body">
			<div className="text-center">
				<h2>ATMs y Kioskos</h2>
				<img src={logo_ba} alt=""  />
			</div>
		</div>
	  </div>)}
      
    </>
  );
}
export default Splash;

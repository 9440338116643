import React from "react";
import "./assets/forgotUser.css";

const Modal = ({ open, onClose }) => {
  if (!open) return null;
  return (
    <div className="overlay">
      <div className="modal-container">
        <p className="modal-txt">
          Revisa tu correo electrónico para continuar con el proceso
        </p>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <button onClick={onClose} className="btn-login">
            Aceptar
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;

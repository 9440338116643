import React, { useState, useEffect } from "react";
import backgroundImage from "./assets/img/background.svg";
import "./assets/home.css";
 
import  serviceApi from "../../services/services";
import Header from "../header/header";
import { useNavigate, useParams } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();
  let { tipo  } = useParams();
  const [toggleState, setToggleState] = useState(1);
  const [indicadores,setIndicadores] = useState({});
  const [indicadoresKiosko,setIndicadoresKiosko] = useState({});
  let newDate = new Date();
  let hora = (newDate.getHours()<10?"0"+newDate.getHours():newDate.getHours())+":"+
		(newDate.getMinutes()<10?"0"+ newDate.getMinutes():newDate.getMinutes());

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const getIndicadoresData=()=>{
    serviceApi.post("equipo/indicadores/1" ).then((response)=>{
      if(response.data.code===200){
        setIndicadores(response.data.data.indicadores);
      }
    });
    serviceApi.post("equipo/indicadores/2").then((response)=>{
      if(response.data.code===200){
        setIndicadoresKiosko(response.data.data.indicadores);
      }
    });
  };

  const verMapa=()=>{
    navigate("/fallas-activas/"+(toggleState==1?'atm':'kiosko'));
  }

  useEffect(()=>{
    document.body.classList.remove("remove-bg");
    if(tipo=="kiosko"){
      setToggleState(2);
    }
    getIndicadoresData( );
  }, [])
   

  return (
    <div className="container p-0 m-0">
      <Header />

      <div className="mt-2 d-flex flex-row px-5">
        <div
          className={
            toggleState === 1 ? "container-tab active-tabs" : "container-tab"
          }
          onClick={() => toggleTab(1)}
        >
          <p className="txt-tabs">ATMs</p>
        </div>
        <div
          className={
            toggleState === 2 ? "container-tab active-tabs" : "container-tab"
          }
          onClick={() => toggleTab(2)}
        >
          <p className="txt-tabs">Kioskos</p>
        </div>
      </div>
      
      <div className="ContainerTab">
        <div
          className={
            toggleState === 1 ? "Content-tab active-content" : "Content-tab"
          }
        >
          <div className="Container-card mt-4">
            <div className="Card-info card-color">
              <p className="number-info">{indicadores.porcentaje_activos}%</p>
              <p className="txt-info">
                Disponibilidad<br />al cierre del día anterior
              </p>
            </div>

            <div className="Card-info card-color margin-div" onClick={verMapa}>
              <p className="number-info">{indicadores.fallas}</p>
              <p className="txt-info">Fallas activas a las {hora}</p>
            </div>
          </div>

          <div className="Container-card mt-4">
            <div className="Card-info2 card-color2"  onClick={()=> navigate("/equipo-servicio/atm/dispensador")}>
              <p className="number-info">{indicadores.dispensadores_activos}</p>
              <p className="txt-info">ATMS dispensadores en servicio</p>
            </div>

            <div className="Card-info2 card-color2 margin-div" onClick={()=> navigate("/equipo-servicio/atm/depositario")}>
              <p className="number-info">{indicadores.depositarios_activos}</p>
              <p className="txt-info">ATMS depositarios en servicio</p>
            </div>
          </div>

          <div className="Container-card mt-4">
            <div className="Card-info2 card-color3" onClick={()=> navigate("/fallas-lista/atm/0/sinservicio/dispensador")}>
              <p className="number-info">{indicadores.dispensadores_inactivos}</p>
              <p className="txt-info">ATMS dispensadores fuera de servicio</p>
            </div>

            <div className="Card-info2 card-color3 margin-div" onClick={()=> navigate("/fallas-lista/atm/0/sinservicio/depositario")}>
              <p className="number-info">{indicadores.depositarios_inactivos}</p>
              <p className="txt-info">ATMS depositarios fuera en servicio</p>
            </div>
          </div>
        </div>
        <div
          className={
            toggleState === 2 ? "Content-tab active-content" : "Content-tab"
          }
        >
          <div className="Container-card mt-4">
            <div className="Card-info card-color">
              <p className="number-info">{indicadoresKiosko.porcentaje_activos}%</p>
              <p className="txt-info">
              Disponibilidad<br />al cierre del día anterior
              </p>
            </div>

            <div className="Card-info card-color margin-div" onClick={verMapa}>
              <p className="number-info">{indicadoresKiosko.fallas}</p>
              <p className="txt-info">Fallas activas a las {hora}</p>
            </div>
          </div>
          <div className="Container-card mt-4"  >
            <div className="Card-info2 card-color2" onClick={()=> navigate("/equipo-servicio/kiosko/1")}>
              <p className="number-info">{indicadoresKiosko.activos}</p>
              <p className="txt-info">Kioskos en servicio</p>
            </div>

            <div className="Card-info2 card-color3 margin-div" onClick={()=> navigate("/fallas-lista/kiosko/0/sinservicio/1")}>
              <p className="number-info">{indicadoresKiosko.inactivos}</p>
              <p className="txt-info">Kioskos fuera de servicio</p>
            </div>
          </div>
        </div>
      </div>

     
    </div>
  );
};

export default Home;

import React, { useState } from "react";
import logo from "./assets/img/logo.svg";
import iconUser from "./assets/img/icon-user.svg";
import "./assets/forgotPass.css";
import Modal from "./modal";

import  serviceApi from "../../services/services";
import AlertModal from "../alertModal/AlertModal";
import { useNavigate, useParams } from "react-router-dom";

const ForgotPass = () => {
  const [openModal, setopenModal] = useState(false);
  const [modal,setModal] = useState({show:false,error:false,message:"",setModal:null});
  const [sending,setSending] = useState(false);
  const [formData,setFormData] = useState({correo:""});
  const [mensaje,setMensaje] = useState({});

  const navigate  = useNavigate();
  let { is_user  } = useParams();
  const callbackHome =()=>{
    navigate("/");
  }
  const handleChange=(e)=>{
    setFormData({correo:e.target.value});
  }
  const submit =(e)=>{
    e.preventDefault();
   setSending(true);
   setMensaje({});
   formData.is_user=is_user?1:0;
   serviceApi.post("usuario/recuperar",formData).then(function(response){
    if(response.data.code!=200){
      setMensaje({
        show:true,
        message:"Correo incorrecto"
      })
    }else{
      setModal({
        show:true,
        error:false,
        message:response.data.msj,
        setModal:setModal,
        callback:callbackHome
      });
      setFormData({correo:""});
    }
    setSending(false);
  },function(response){
    setModal({
      show:true,
      error:true,
      message:"Error, intente más tarde",
      setModal:setModal
    });
    setSending(false);
  });
  return false;

  }

  return (
    <div className="forget-body p-0 m-0">
      <div className="d-flex flex-column justify-content-center align-items-center mt-4 register-header">
      <span className="back-button" onClick={(e)=>navigate("/")}><a href="javascript:void(0)"><span className="ico-prev2"></span></a></span>
        <img className="" src={logo} alt="logo" />
        <p className="txt-login mt-2">
          Bienvenido a la aplicación de Sistema de gestión ATMs y Kioskos
        </p>
        <p className="txt-forget mt-5">
        <b>{(!is_user?"Olvido de clave o desbloqueo de usuario":"Olvido de usuario")}</b>
          
        </p>
      </div>
    <form action="" onSubmit={submit}>
      <div className="container-inputs mt-3">
        <p className="txt-indicador">
        {(!is_user?"Por favor ingrese su correo para recuperar tu clave o desbloquear tu usuario":"Por favor ingrese su correo para recuperar tu usuario")}
          
        </p>
        <div className="container-input-title">
          <img className="" src={iconUser} alt="icon-user" />
          <p className="txt-user">Correo electrónico</p>
        </div>
        <input className="input-user w-100" type="text" name="correo" onChange={handleChange} value={formData.correo} required/>
        {mensaje.show?(<label className="lbl-error text-left">{mensaje.message}</label>):""}
        <div className="d-flex flex-column justify-content-center align-items-center mt-5">
          <button className={"btn-login mt-5 "+(sending && "btn-loading ")} disabled={sending} >
            Continuar
          </button>
          
          <Modal open={openModal} onClose={() => {setopenModal(false); navigate("/");}}  />
          <AlertModal show={modal.show} setShow={modal.setModal} error={modal.error} message={modal.message} callback={() => { navigate("/");}}></AlertModal>
          
        </div>
      </div>
      </form>
    </div>
  );
};

export default ForgotPass;

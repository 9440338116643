import React, { useState,useEffect } from "react";
import logo from "./assets/img/logo.svg";
import iconUser from "./assets/img/icon-user.svg";
import iconPassword from "./assets/img/icon-pass.svg";
import iconMail from "./assets/img/icon-mail.svg";
import "./assets/register.css";
import  serviceApi from "../../services/services";
import AlertModal from "../alertModal/AlertModal";
import { useNavigate  } from "react-router-dom";

const RegisterUser = () => {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [passwordType2, setPasswordType2] = useState("password");
  const [password, cambiarPassword] = useState({ campo: "", valido: null });
  const [password2, cambiarPassword2] = useState({ campo: "", valido: null });
  const [message, setMessage] = useState("");
  const [error, setError] = useState(null);
  const [user, setUser] = useState({ campo: "", valido: null });
  const [nombreCompleto,setNombreCompleto] = useState("");
  const [modal,setModal] = useState({show:false,error:false,message:"",setModal:null});
  const [validationUserPass,setValidationUserPass] = useState([false,false,false]);
  const [validationClavePass,setValidationClavePass] = useState([false,false,false,false]);
  const [sending,setSending] = useState(false);
  const [msjCorreo,setMsjCorreo]=useState({});
  const [msjUsuario,setMsjUsuario]=useState({});
  const [msjClave,setMsjClave]=useState({});
  const [msjClave2,setMsjClave2]=useState({});
  const [msjError,setMsjError]=useState({});

  const navigate  = useNavigate();
  const callbackHome =()=>{
    navigate("/");
  }
 

  const [validUser, setValidateUser] = useState({
    largeUser: false,
    mayusculaUser: true,
    numberUser: true,
  });
  const [validatePassword, setValidatePassword] = useState({
    large: false,
    mayuscula: false,
    number: false,
    especial: false,
  });

  const expresiones = {
    passwordLarge: /^.{8,20}$/,
    passwordMayuscula: /[A-Z]/,
    passwordNumber: /[0-9]/,
    passwordEspecial: /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/,
    userLarge: /^.{4,12}$/,
    userMayuscula: /[A-Z]/,
    userNumber: /[0-9]/,
  };

  const onChangeUser = async (e) => {
    setUser({
      campo: e.target.value,
      valido: e.target.value,
    });
  };

  const validateUser = () => {
    var current_pass = validationUserPass;
    if (expresiones) {
      if (expresiones.userLarge.test(user.campo)) {
        console.log("User Largo valido");
        setValidateUser({
          ...validUser,
          largeUser: true,
        });
        current_pass[0]=true;
        setShow2(false);
      } else {
        console.log("User Largo no valido");
        setValidateUser({
          ...validUser,
          largeUser: false,
        });
        current_pass[0]=false;
        setShow2(true);
      }
      /*
      if (expresiones.userMayuscula.test(user.campo)) {
        console.log("User mayuscula valido");
        setValidateUser({
          ...validUser,
          mayusculaUser: true,
        });
        current_pass[1]=true;
      } else {
        console.log("User mayuscula no valido");
        setValidateUser({
          ...validUser,
          mayusculaUser: false,
        });
        current_pass[1]=false;
      }
      if (expresiones.userNumber.test(user.campo)) {
        console.log("User numero valido");
        setValidateUser({
          ...validUser,
          numberUser: true,
        });
        setShow2(false);
        current_pass[2]=true;
      } else {
        console.log("User numero no valido");
        setValidateUser({
          ...validUser,
          numberUser: false,
        });
        setShow2(true);
        current_pass[2]=false;
      }*/
    }
    current_pass[1]=true;
    current_pass[2]=true;
    setValidationUserPass(current_pass);
    console.log(validationUserPass)
  };

  const validacion = () => {
    var current_pass = validationClavePass;
    if (expresiones) {
      if (expresiones.passwordLarge.test(password.campo)) {
        console.log("largo valido");
        setValidatePassword({
          ...validatePassword,
          large: true,
        });
        current_pass[0]=true;
      } else {
        setValidatePassword({
          ...validatePassword,
          large: false,
        });
        console.log("largo invalida");
        current_pass[0]=false;
      }
      if (expresiones.passwordMayuscula.test(password.campo)) {
        console.log("mayuscula valida");
        setValidatePassword({
          ...validatePassword,
          mayuscula: true,
        });
        current_pass[1]=true;
      } else {
        setValidatePassword({
          ...validatePassword,
          mayuscula: false,
        });
        console.log("mayuscula invalida");
        current_pass[1]=false;
      }
      if (expresiones.passwordNumber.test(password.campo)) {
        console.log("numero valido");
        setValidatePassword({
          ...validatePassword,
          number: true,
        });
        current_pass[2]=true;
      } else {
        setValidatePassword({
          ...validatePassword,
          number: false,
        });
        console.log("numero invalido");
        current_pass[2]=false;
      }

      if (expresiones.passwordEspecial.test(password.campo)) {
        console.log("especial valido");
        setValidatePassword({
          ...validatePassword,
          especial: true,
        });
        setShow(false);
        current_pass[3]=true;
      } else {
        setValidatePassword({
          ...validatePassword,
          especial: false,
        });
        console.log("especial invalido");
        setShow(true);
        current_pass[3]=false;
      }
    }
    setValidationClavePass(current_pass);
    console.log(validationClavePass)
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const togglePassword2 = () => {
    if (passwordType2 === "password") {
      setPasswordType2("text");
      return;
    }
    setPasswordType2("password");
  };

  const onChangePassword = async (e) => {
    cambiarPassword({
      campo: e.target.value,
      valido: e.target.value,
    });
  };

  const onChangePassword2 = async (e) => {
    cambiarPassword2({
      campo: e.target.value,
      valido: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/.test(e.target.value),
    });
  };

  const validarPassword2 = () => {
    if (password.campo.length > 0) {
      if (password.campo !== password2.campo) {
        cambiarPassword2((prevState) => {
          return { ...prevState, valido: "false" };
        });
        console.log("contraseña no coincide");
      } else {
        cambiarPassword2((prevState) => {
          return { ...prevState, valido: "true" };
        });
        console.log("contraseña coincide");
      }
    }
  };

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const handleChange = (event) => {
    if (!isValidEmail(event.target.value)) {
      setError("Ingrese un correo válido");
    } else {
      setError(null);
    }

    setMessage(event.target.value);
  };

  const hadleNombre =(event) => {
    setNombreCompleto(event.target.value);
  }
  const handleRegistrar=(event) =>{
    event.preventDefault();
    console.log(validationUserPass);
    setMsjClave({});
    setMsjClave2({});
    setMsjCorreo({});
    setMsjUsuario({});
    setMsjError({});

    if(validationUserPass.filter(x=>x==false).length>0){
      setMsjUsuario({
        show:true,
        message:"Usuario inválido"
      });
      return false;
    }

    if(validationClavePass.filter(x=>x==false).length>0){

      setMsjClave({
        show:true,
        message:"Contraseña no cumple requisitos"
      });
      return false;
    }

    let dataPost={
      nombre_completo:nombreCompleto,
      usuario:user.campo,
      clave:password.campo,
      clave2:password2.campo,
      correo:message
    };

    setSending(true);
    
    serviceApi.post("usuario/registrar",dataPost).then(function(response){
        if(response.data.code!=200){
          switch(response.data.data.input){
                case 'correo':
                  setMsjCorreo({
                    show:true,
                    message:response.data.msj
                  });
                break;
                case 'usuario':
                  setMsjUsuario({
                    show:true,
                    message:response.data.msj
                  });
                break;
                case 'clave':
                  setMsjClave2({
                    show:true,
                    message:response.data.msj
                  });
                break;
          }
          
        }else{
          setModal({
            show:true,
            error:false,
            message:response.data.msj,
            setModal:setModal,
            callback:callbackHome
          });
          setNombreCompleto("");
          setMessage("");
          setUser({ campo: "", valido: null });
          cambiarPassword({ campo: "", valido: null });
          cambiarPassword2({ campo: "", valido: null });
        }
        setSending(false);
    });
  }
  return (
    <div className="register-body">
      <div className="d-flex flex-column justify-content-center align-items-center mt-4 register-header">
        <span className="back-button" onClick={(e)=>navigate("/")}><a href="javascript:void(0)"><span className="ico-prev2"></span></a></span>
        <img className="" src={logo} alt="logo" />
        <p className="txt-login mt-2">
          Bienvenido a la aplicación de Sistema de gestión ATMs y Kioskos
        </p>
      </div>

   
      <form action="" onSubmit={handleRegistrar}>
      <div className="container-inputs mt-2">
        <div className="container-input-title">
          <img className="img-mail" src={iconUser} alt="icon-mail" />
          <p className="txt-user">Nombre completo</p>
        </div>
        <input
          className="input-user w-100"
          type="text"
          id="nombre_completo"
          name="nombre_completo"
          value={nombreCompleto}
          onChange={hadleNombre}
           required
        />
        
      </div>
      <div className="container-inputs mt-2">
        <div className="container-input-title">
          <img className="img-mail" src={iconMail} alt="icon-mail" />
          <p className="txt-user">Correo electrónico</p>
        </div>
        <input
          className="input-user w-100"
          type="mail"
          id="message"
          name="message"
          value={message}
          onChange={handleChange}
          required
        />
        {error && <p className="txt-error">{error}</p>}
        {msjCorreo.show && <p className="txt-error">{msjCorreo.message}</p>}
      </div>
      <div className="container-inputs mt-3">
        <div className="container-input-title">
          <img className="" src={iconUser} alt="icon-user" />
          <p className="txt-user">Usuario</p>
        </div>
        <input
          className="input-user w-100"
          type="text"
          value={user.campo}
          onChange={onChangeUser}
          onKeyUp={validateUser}
          onFocus={validateUser}
          valido={user.valido}
          required
        />
        {msjUsuario.show && <p className="txt-error">{msjUsuario.message}</p>}
        {show2 && (
          <div className="container-checkbox mt-3">
            <p className="txt-checkbox-title">Debe contener al menos:</p>
            <div className="container-check">
              <input className="check-default" type="checkbox" checked={validationUserPass[0]} />
              <p className="txt-validate">Entre 4 a 12 carácteres</p>
            </div>
            <div className="container-check d-none">
              <input type="checkbox" readOnly checked={validationUserPass[1]} />
              <p className="txt-validate">1 mayúscula</p>
            </div>
            <div className="container-check d-none">
              <input type="checkbox"  readOnly checked={validationUserPass[2]}/>
              <p className="txt-validate">1 número</p>
            </div>
          </div>
        )}
      </div>
      <div className="container-inputs mt-4">
        <div className="container-input-title">
          <img className="" src={iconPassword} alt="icon-user" />
          <p className="txt-user">Clave</p>
        </div>
        <input
          className="input-user w-100"
          type={passwordType}
          value={password.campo}
          onChange={onChangePassword}
          onKeyUp={validacion}
          onFocus={validacion}
          valido={password.valido}
          required
        />
        {
          password.campo!="" && (
            <button type="button" className="btn-show-pass" onClick={togglePassword}>
            {passwordType === "password" ? (
              <i className="fa-light fa-eye"></i>
            ) : (
              <i className="fa-light fa-eye-slash"></i>
            )}
          </button>
          )
        }
        
        {msjClave.show && <p className="txt-error">{msjClave.message}</p>}
        {show && (
          <div className="container-checkbox mt-3">
            <p className="txt-checkbox-title">Debe contener al menos:</p>
            <div className="container-check">
              <input className="check-default" type="checkbox" checked={validationClavePass[0]} />
              <p className="txt-validate">Entre 8 a 20 carácteres</p>
            </div>
            <div className="container-check" >
              <input className="check-default" type="checkbox" checked={validationClavePass[1]}/>
              <p className="txt-validate">1 mayúscula</p>
            </div>
            <div className="container-check" >
              <input className="check-default" type="checkbox" checked={validationClavePass[2]} />
              <p className="txt-validate">1 número</p>
            </div>
            <div className="container-check" >
              <input className="check-default" type="checkbox" checked={validationClavePass[3]} />
              <p className="txt-validate">1 Símbolo ñ&*_!&#@-+:¿¡</p>
            </div>
          </div>
        )}
      </div>
      <div className="container-inputs mt-4">
        <div className="container-input-title">
          <img className="" src={iconPassword} alt="icon-user" />
          <p className="txt-user">Confirmar clave</p>
        </div>
        <input
          className="input-user w-100"
          type={passwordType2}
          onChange={onChangePassword2}
          value={password2.campo}
          onKeyUp={validarPassword2}
          onBlur={validarPassword2}
          valido={password2.valido}
          required
        />
        {
          password2.campo!="" && (
            <button  type="button" className="btn-show-pass" onClick={togglePassword2}>
              {passwordType2 === "password" ? (
                <i className="fa-light fa-eye"></i>
              ) : (
                <i className="fa-light fa-eye-slash"></i>
              )}
            </button>
          )
        }
        
        {msjClave2.show && <p className="txt-error">{msjClave2.message}</p>}
      </div>
      <div className="d-flex flex-column justify-content-center align-items-center my-4">
        <button className={"btn-login mt-5 "+(sending && "btn-loading ")} disabled={sending} >Registrar</button>
      </div>
      </form>

      <AlertModal show={modal.show} setShow={modal.setModal} error={modal.error} message={modal.message} callback={modal.callback}></AlertModal>
    </div>
  );
};

export default RegisterUser;

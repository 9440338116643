import React, { useState } from "react";
import logos from "./assets/img/logo.svg";
import iconUser from "./assets/img/icon-user.svg";
import "./assets/forgotUser.css";
import Modal from "./modal";
import { useNavigate  } from "react-router-dom";

const ForgotUser = () => {
  const [openModal, setopenModal] = useState(false);
  const navigate  = useNavigate();

  return (
    <div className="forget-body p-0 m-0">
      <div className="d-flex flex-column justify-content-center align-items-center mt-4 register-header">
        <img className="" src={logos} alt="logo" />
        <p className="txt-login mt-2">
          Bienvenido a la aplicación de Sistema de gestión ATMs y Kioskos
        </p>
        <p className="txt-forget mt-5">Olvido de de usuario</p>
      </div>
      <div className="container-inputs mt-3">
        <p className="txt-indicador">
          Por favor ingrese su correo para recuperar tu usuario
        </p>
        <div className="container-input-title">
          <img className="" src={iconUser} alt="icon-user" />
          <p className="txt-user">Usuario</p>
        </div>
        <input className="input-user w-100" type="text" />
        <div className="d-flex flex-column justify-content-center align-items-center mt-5">
          <button className="btn-login mt-5" onClick={() => setopenModal(true)}>
            Continuar
          </button>
          <Modal open={openModal} onClose={() => {setopenModal(false); navigate("/");}}  />
        </div>
      </div>
    </div>
  );
};

export default ForgotUser;

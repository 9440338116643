import location from "./assets/img/location.svg";
import house from "./assets/img/house.svg";
import light from "./assets/img/light.svg";
import logout_ico from "./assets/img/logout.svg";

export const SidebarData = [
  {
    title: "Buscar cajeros cercanos",
    path: "/equipo-servicio/atm/dispensador",
    icon: location,
    cName: "nav-text nav-text-1",
  },
  {
    title: "Red ATMS",
    path: "/fallas-activas/atm",
    icon: house,
    cName: "nav-text",
  },
  {
    title: "Novedades",
    path: "/novedades",
    icon: light,
    cName: "nav-text",
  },
  {
    title: "Cerrar sesión",
    path: "/logout",
    icon: logout_ico,
    cName: "nav-text-2",
  },
];

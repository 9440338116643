import React, { useState } from "react";
import logo from "./assets/img/logo.png";
import iconPassword from "./assets/img/icon-pass.png";
import check from "./assets/img/circle.png";
import "./assets/confirmPass.css";
import { useSearchParams } from "react-router-dom";
import  serviceApi from "../../services/services";
import AlertModal from "../alertModal/AlertModal";
import { useNavigate  } from "react-router-dom";

const ConfirmPass = () => {
  const [passwordType, setPasswordType] = useState("password");
  const [passwordType2, setPasswordType2] = useState("password");
  const [password, cambiarPassword] = useState({ campo: "", valido: null });
  const [password2, cambiarPassword2] = useState({ campo: "", valido: null });
  const [show, setShow] = useState(false);
  const [validatePassword, setValidatePassword] = useState({
    large: false,
    mayuscula: false,
    number: false,
    especial: false,
  });

  const [modal,setModal] = useState({show:false,error:false,message:"",setModal:null});
  const [sending,setSending] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const navigate  = useNavigate();
  const expresiones = {
    passwordLarge: /^.{8,20}$/,
    passwordMayuscula: /[A-Z]/,
    passwordNumber: /[0-9]/,
    passwordEspecial: /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/,
  };

  const validacion = () => {
    if (expresiones) {
      if (expresiones.passwordLarge.test(password.campo)) {
        console.log("largo valido");
        setValidatePassword({
          ...validatePassword,
          large: true,
        });
      } else {
        setValidatePassword({
          ...validatePassword,
          large: false,
        });
        console.log("largo invalida");
      }
      if (expresiones.passwordMayuscula.test(password.campo)) {
        console.log("mayuscula valida");
        setValidatePassword({
          ...validatePassword,
          mayuscula: true,
        });
      } else {
        setValidatePassword({
          ...validatePassword,
          mayuscula: false,
        });
        console.log("mayuscula invalida");
      }
      if (expresiones.passwordNumber.test(password.campo)) {
        console.log("numero valido");
        setValidatePassword({
          ...validatePassword,
          number: true,
        });
      } else {
        setValidatePassword({
          ...validatePassword,
          number: false,
        });
        console.log("numero invalido");
      }

      if (expresiones.passwordEspecial.test(password.campo)) {
        console.log("especial valido");
        setValidatePassword({
          ...validatePassword,
          especial: true,
        });
      } else {
        setValidatePassword({
          ...validatePassword,
          especial: false,
        });
        console.log("especial invalido");
      }

      if (password.campo.length > 0) {
        if (password.campo !== password2.campo) {
          cambiarPassword2((prevState) => {
            return { ...prevState, valido: "false" };
          });
          console.log("contraseña no coincide");
          setShow(true);
        } else {
          cambiarPassword2((prevState) => {
            return { ...prevState, valido: "true" };
          });
          console.log("contraseña coincide");
          setShow(false);
        }
      }
    }
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const togglePassword2 = () => {
    if (passwordType2 === "password") {
      setPasswordType2("text");
      return;
    }
    setPasswordType2("password");
  };

  const onChangePassword = async (e) => {
    cambiarPassword({
      campo: e.target.value,
      valido: e.target.value,
    });
  };

  const onChangePassword2 = async (e) => {
    cambiarPassword2({
      campo: e.target.value,
      valido: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/.test(e.target.value),
    });
  };

  const submit =(e)=>{
    e.preventDefault();
   setSending(true);
    let formData={
      clave: password.campo,
      clave2:password2.campo,
      token:searchParams.get("token")
    }
   serviceApi.post("usuario/confirmar_clave",formData).then(function(response){
    if(response.data.code!=200){
      setModal({
        show:true,
        error:true,
        message:response.data.msj,
        setModal:setModal
      });
    }else{
      setModal({
        show:true,
        error:false,
        message:response.data.msj,
        setModal:setModal
      });
      cambiarPassword({campo:""});
      cambiarPassword2({campo:""});
      setTimeout(()=>{
        navigate("/");
      },3000);
      //setFormData({correo:""});
    }
    setSending(false);
  },function(response){
    setModal({
      show:true,
      error:true,
      message:"Error, intente más tarde",
      setModal:setModal
    });
    setSending(false);
  });
  return false;

  }

  return (
    <div className="login-body p-0 m-0">
      <div className="d-flex flex-column justify-content-center align-items-center mt-4 login-header register-header">
      <span className="back-button"><a href="/"><span className="ico-prev2"></span></a></span>
        <img className="" src={logo} alt="logo" />
        <p className="txt-login mt-2">
          Bienvenido a la aplicación de Sistema de gestión ATMs y Kioskos
        </p>
      </div>
      <form action="" onSubmit={submit}>
      <div className="container-inputs mt-4">
        <div className="container-input-title">
          <img className="" src={iconPassword} alt="icon-user" />
          <p className="txt-user">Clave</p>
        </div>
        <input
          className="input-user w-100"
          type={passwordType}
          value={password.campo}
          onChange={onChangePassword}
          onKeyUp={validacion}
          onFocus={validacion}
          valido={password.valido}
          required
        />
        <button className="btn-show-pass" onClick={togglePassword} type="button">
          {passwordType === "password" ? (
            <i className="fa-light fa-eye"></i>
          ) : (
            <i className="fa-light fa-eye-slash"></i>
          )}
        </button>
      </div>
      <div className="container-inputs mt-4">
        <div className="container-input-title">
          <img className="" src={iconPassword} alt="icon-user" />
          <p className="txt-user">Confirmar clave</p>
        </div>
        <input
          className="input-user w-100"
          type={passwordType2}
          onChange={onChangePassword2}
          value={password2.campo}
          onKeyUp={validacion}
          onBlur={validacion}
          valido={password2.valido}
          required
        />
        <button className="btn-show-pass" onClick={togglePassword2} type="button">
          {passwordType2 === "password" ? (
            <i className="fa-light fa-eye"></i>
          ) : (
            <i className="fa-light fa-eye-slash"></i>
          )}
        </button>
        {show && (
          <div className="container-checkbox mt-3">
            <p className="txt-checkbox-title">La clave debe contener</p>
            <div className="container-check">
            <img className="" src={check} alt="icon-check" />
              <p className="txt-validate">Minímo 8 caracteres</p>
            </div>
            <div className="container-check">
            <img className="" src={check} alt="icon-check" />
              <p className="txt-validate">Letras mayúsculas y minúsculas</p>
            </div>
            <div className="container-check">
            <img className="" src={check} alt="icon-check" />
              <p className="txt-validate">Al menos un carácter especial(@,%,$,etc</p>
            </div>
            <div className="container-check">
              <img className="" src={check} alt="icon-check" />
              <p className="txt-validate">Al menos un número</p>
            </div>
          </div>
        )}
      </div>
      <div className="d-flex flex-column justify-content-center align-items-center my-4">
        <button className={"btn-login mt-5 "+(sending && "btn-loading ")} disabled={sending}>Continuar</button>
      </div>
      <AlertModal show={modal.show} setShow={modal.setModal} error={modal.error} message={modal.message}></AlertModal>
      </form>
    </div>
  );
};

export default ConfirmPass;

import React from "react";
import { Navigate } from "react-router-dom";

function PrivateRoute({ children, user, ...rest }) {
  
  console.log(user);
  const auth = user? user.token:null;
  return auth ? children : <Navigate to="/" />;
}

export default PrivateRoute;

import React, { useState,useEffect } from "react";
import "./assets/fallasLista.css";
import Header from "../header/header";
import  serviceApi from "../../services/services";
import tuerca from "./assets/img/tuerca.svg";
import cicle from "./assets/img/repeticion.svg";
import search from "./assets/img/busqueda.svg";
import calendary from "./assets/img/calendario.svg";
import SelectNomemclatura from "../selectNomemclatura/selectNomemclatura";
import { useNavigate, useParams } from "react-router-dom";

const FallasLista = () => {
	let { tipo,depto,tipo_servicio } = useParams();
	const [departamento, setDepartamento] = useState(0);
	const [fallas,setFallas] = useState([]);
	const [historico,setHistorico]= useState([]);
	const [vista,setVista] = useState('fallas');
	const [showBack,setShowBack] = useState(true);
	const [equiposSinServicio,setEquiposSinServicio] = useState(0);
	const [equiposCerrados,setEquiposCerrados] = useState(0);
	const [equiposCerradosDotacion,setEquiposCerradosDotacion] = useState(0);

	const [nomenclatura,setNomenclatura] = useState([]);
	const navigate = useNavigate();
	const [filtroGrupo,setFiltroGrupo]=useState('sinservicio');

	const verDetalles = (e) => {
		let departamento = e.target.value;
		setDepartamento(departamento);
		getEquiposFallasDepartamento(departamento);
	};
	const getEquiposFallasDepartamento = (id_deptop)=>{
		setFallas([]);
		console.log(tipo_servicio);
		serviceApi.post("equipo/getequiposfallasdepartamento/"+tipo+"/"+id_deptop+"/"+(tipo_servicio?"1":"0")+"/"+(tipo_servicio??"")).then(response=>{
			setFallas(response.data.data.fallas);
			setEquiposSinServicio(response.data.data.equipos_sin_servicio);
			setEquiposCerrados(response.data.data.equipos_cerrados);
			setEquiposCerradosDotacion(response.data.data.equipos_cerrados_dotacion);
		});
	};
	
	useEffect(()=>{
		serviceApi.post("catalogo/nomenclatura").then((response)=>{
			setNomenclatura(response.data.data.nomenclatura);
		});
		setDepartamento(depto );
		getEquiposFallasDepartamento(depto);
	}, []);

	const verHistorico= (data)=>{
		setHistorico(data??[]);
		setVista("historico");
		setShowBack(true);
	};
	const regresar = ()=>{
		if(vista=="historico"){
			setVista("fallas");
		}else{
			if(tipo_servicio==undefined){
				navigate("/fallas-activas/"+tipo);
			}else{
				navigate("/home/"+tipo);
			}
			
		}
		
		
	}

	function filtrarGrupo(tipo){
		setFiltroGrupo(tipo!=filtroGrupo?tipo:'');
		 
	}
	
	return(
		<div className="container p-0 m-0">
			<Header showBack={showBack} callback={regresar}/>
			{vista=="fallas"?(
			<>
				<div className="container-select mt-3">
					<p className="txt-select">Departamento:</p>
					<SelectNomemclatura lista={nomenclatura} tipo="D" className="select-departamento" onChange={verDetalles} value={departamento}></SelectNomemclatura>
				</div>
				<div className="active-content">
					<div className={"Container-card mt-4 "+tipo}>
						<div className={"Card-info card-color3 "+(filtroGrupo=='sinservicio' && 'filtro-activo')} onClick={()=>{filtrarGrupo('sinservicio')}}>
							<p className="number-info">{equiposSinServicio}</p>
				 
							<p className="txt-info">{tipo=='atm'?'ATMs':'Kioskos'} {tipo=='atm'? "cerrados por falla":"fuera de servicio"}</p>
						</div>
					
						
						<div className={"Card-info card-color3 margin-div "+(filtroGrupo=='cerrados' && 'filtro-activo')} onClick={()=>{filtrarGrupo('cerrados')}}>
							<p className="number-info">{equiposCerrados}</p>
							<p className="txt-info">{tipo=='atm'?'ATMs':'Kioskos'} cerrados por Anfitrión</p>
						</div>
						{
							tipo=='atm' && (
								<div className={"Card-info card-color3 margin-div "+(filtroGrupo=='dotacion_bna' && 'filtro-activo')} onClick={()=>{filtrarGrupo('dotacion_bna')}} >
									<p className="number-info">{equiposCerradosDotacion}</p>
									<p className="txt-info">ATMs cerrados por {tipo_servicio == 'dispensador'? 'Dotación Correctivas' : 'BNA Full'}</p>
								</div>
							)
						}
						
					</div>
					<div className="listaFallas">
						{fallas.filter(x=>(
										filtroGrupo=='' 
										|| (filtroGrupo=='sinservicio' && x.afecta_disponibilidad=='1' && x.dotacion_bna==0) 
										|| (filtroGrupo=='cerrados' && x.afecta_disponibilidad=='0') 
										|| (filtroGrupo=='dotacion_bna' && x.dotacion_bna==1) 
										)).map(item=>(
							<ItemFalla item={item} onClick={verHistorico}></ItemFalla>
						))}
						{fallas.length==0?<div className="text-center mt-5"><h4>Sin resultados</h4></div>:""}
					</div>
				</div>
			</>
			)
			:
			(
				<>
				<div className="text-center">
					<label htmlFor="" className="lbl-green">Historico de fallas</label>
				</div>
				{historico.map(item=>(
					<ItemHistorico item={item}></ItemHistorico>
				))}
				
				</>
			)}
		</div>
	)
}
export default FallasLista;

const ItemFalla = (props)=>{
	return (<div className="Container-card-info mt-4">
	<p className="title-txt-cajero">
		{props.item.codigo_equipo} - {props.item.nombre_equipo}
	</p>
	<div className="d-flex flex-row justify-content-between mt-2">
		<div className="container-card-falla d-flex flex-row">
			<img className="img-tuerca" src={tuerca} alt="tuerca" />
			<p className="txt-falla">Falla</p>
		</div>
		<div className="container-card-falla-2">
			<p className="txt-falla-2">: {props.item.falla}</p>
		</div>
	</div>
	<div className="d-flex flex-row justify-content-between mt-1">
		<div className="container-card-falla d-flex flex-row">
			<img className="img-tuerca" src={cicle} alt="tuerca" />
			<p className="txt-falla">Tiempo de afectación</p>
		</div>
		<div className="container-card-falla-2 ">
			<p className="txt-falla-2">: {props.item.tiempo}</p>
		</div>
	</div>
	<div className="d-flex flex-row justify-content-between mt-1 mb-2">
		<div className="container-card-falla d-flex flex-row">
			<img className="img-tuerca" src={calendary} alt="tuerca" />
			<a href="javascript:void(0)" className="link-bottom" onClick={(e)=>props.onClick(props.item.historico)}>Ver Histórico</a>
		
		</div>
	</div>
</div>)
}
const ItemHistorico = (props)=>{
	return (<div className="Container-card-info mt-4">
	
	<div className="d-flex flex-row justify-content-between mt-2">
		<div className="container-card-falla d-flex flex-row">
			<img className="img-tuerca" src={tuerca} alt="tuerca" />
			<p className="txt-falla">Falla</p>
		</div>
		<div className="container-card-falla-2">
			<p className="txt-falla-2">: {props.item.falla}</p>
		</div>
	</div>
	<div className="d-flex flex-row justify-content-between mt-1">
		<div className="container-card-falla d-flex flex-row">
			<img className="img-tuerca" src={cicle} alt="tuerca" />
			<p className="txt-falla">Tiempo de duración</p>
		</div>
		<div className="container-card-falla-2 ">
			<p className="txt-falla-2">: {props.item.tiempo}</p>
		</div>
	</div>
	<div className="d-flex flex-row justify-content-between mt-1">
		<div className="container-card-falla d-flex flex-row">
		<img className="img-tuerca" src={calendary} alt="tuerca" />
			<p className="txt-falla">Fecha</p>
		</div>
		<div className="container-card-falla-2 ">
			<p className="txt-falla-2">: {props.item.fecha}</p>
		</div>
	</div>
</div>)
}


import { createContext } from 'react';

export const setSession = (data)=>{
	if(data){
		localStorage.setItem("WPAUSER",JSON.stringify(data));
	}else{
		localStorage.removeItem("WPAUSER");
	}
	
}

export const getSession  =()=>{
	let userData = localStorage.getItem("WPAUSER") && localStorage.getItem("WPAUSER")!='undefined'? JSON.parse(localStorage.getItem("WPAUSER")):null;
	if(userData && userData.token){
		return userData;
	}else{
		return null;
	}
}

export const AuthContext = createContext(getSession);



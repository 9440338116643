import React, { useContext } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthContext } from "../auth/AuthContext";
import PrivateRoute from "./PrivateRoute";
import Login from "../components/login/login";
import RegisterUser from "../components/register/register";
import AppRoutes from "../routers/AppRoutes";
import ForgotPassword from "../components/forgotPass/forgotPass";
import ForgotUser from "../components/forgotUser/forgotUser";
import ConfirmPass from "../components/confirmPass/confirmPass";
import Home from "../components/home/home";
import FallasActivas from "../components/fallasActivas/fallasActivas";
import Historico from "../components/historico/historico";
import EquipoServicio from "../components/equipoServicio/equipoServicio";
import BuscarCajeros from "../components/buscarCajeros/buscarCajeros";
import Mapa from "../components/mapa/mapa";
import FallasLista from "../components/fallasLista/fallasLista";
import Novedades from "../components/novedades/novedades";
import CambiarClave from "../components/cambiarClave/cambiarClave";

const App = () => {
  const { user } = useContext(AuthContext);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/logout" element={<Login logout={true} />} />
        <Route path="/home" element={<PrivateRoute user={user}><Home /></PrivateRoute>} />
        <Route path="/home/:tipo" element={<PrivateRoute user={user}><Home /></PrivateRoute>} />
        <Route path="/confirmpass" element={<ConfirmPass />} />
        <Route path="/register" element={<RegisterUser />} />
        <Route path="/forgot" element={<ForgotPassword />} />
        <Route path="/forgot/:is_user" element={<ForgotPassword />} />
        <Route path="/forgotUser" element={<ForgotUser />} />
        <Route path="/fallas-activas/:tipo" element={<PrivateRoute user={user}><FallasActivas /></PrivateRoute>} />
        <Route path="/fallas-lista/:tipo/:depto" element={<PrivateRoute user={user}><FallasLista /></PrivateRoute>} />
        <Route path="/fallas-lista/:tipo/:depto/sinservicio/:tipo_servicio" element={<PrivateRoute user={user}><FallasLista /></PrivateRoute>} />
        <Route path="/historico" element={<PrivateRoute user={user}><Historico /></PrivateRoute>} />
        <Route path="/equipo-servicio/:tipo" element={<PrivateRoute user={user}><EquipoServicio /></PrivateRoute>} />
        <Route path="/equipo-servicio/:tipo/:tipo_servicio" element={<PrivateRoute user={user}><EquipoServicio /></PrivateRoute>} />
        <Route path="/buscar-cajeros" element={<PrivateRoute user={user}><BuscarCajeros /></PrivateRoute>} />
        <Route path="/novedades" element={<PrivateRoute user={user}><Novedades /></PrivateRoute>} />
        <Route path="/cambiar-clave" element={<CambiarClave />} />
         

      </Routes>
    </BrowserRouter>
  );
};

export default App;

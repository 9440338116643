import React,{useState,useEffect} from "react";
import Header from "../header/header";
import { useNavigate, useParams } from "react-router-dom";
import serviceApi from "../../services/services";
import $ from "jquery";

const Novedades=(props)=>{
	let { tipo,depto,tipo_servicio } = useParams();
	const navigate = useNavigate();

	const [novedades,setNovedades] = useState([]);
	const getNovedades=()=>{
		serviceApi.post("novedades/getnovedades").then(response=>{
			setNovedades(response.data.data.novedades);
		});
	}
	useEffect(()=>{
		getNovedades();
	},[]);
	return (
		<div className="container p-0 m-0">
		<Header showBack={true} callback={()=>navigate("/home")} />
		<div className="lista-novedades">
			{novedades.map((item,i)=>(
				<div className="item-novedad">
					<div className="item-novedad-header" onClick={(e)=>{$(e.target).parent().hasClass("open")?$(e.target).parent().removeClass("open"):$(e.target).parent().addClass("open")}}>{item.temaNovedad}</div>
					<div className="item-novedad-body">{item.contenidoNovedad}</div>
				</div>
			))}
		</div>
		</div>
	)
}
export default Novedades;
import React, { useState,useEffect } from "react";
import "./assets/depositariosServicio.css";
import Header from "../header/header";
import  serviceApi from "../../services/services";
import info from "./assets/img/Info.svg";
import location from "./assets/img/location.svg";
import { useNavigate, useParams } from "react-router-dom";

import SelectNomemclatura from "../selectNomemclatura/selectNomemclatura";
import AlertModal from "../alertModal/AlertModal";

const EquipoServicio = () => {
  let { tipo,tipo_servicio } = useParams();
  const navigate = useNavigate();

  const [equipos,setEquipos] = useState([]);
  const [nomenclatura,setNomenclatura] = useState([]);
  const [filtroDepto,setFiltroDepto] = useState(0);
  const [filtroMuni,setFiltroMuni] = useState();
  const [latlng,setLatlan]=useState({});
  const [usarGeo,setUsarGeo]=useState(false);
  const [promiseGPS,setPromiseGPS] = useState(null);
  const [modal,setModal] = useState({show:false,error:false,message:"",setModal:null});

  const getEquipos = ()=>{
    let dataPost={
      tipo:tipo,
      tipo_servicio:tipo_servicio,
      departamento:filtroDepto,
      municipio:filtroMuni,
      usar_geo:usarGeo,
      lat:latlng.lat??null,
      lng:latlng.lng??null
    }
    setEquipos([]);
    serviceApi.post("equipo/getEquiposDisponibles",dataPost).then((response)=>{
      if(usarGeo){
      
        setEquipos(response.data.data.equipos.slice(0, 10));
      }else{
        setEquipos(response.data.data.equipos);
      }
		});
  }

  useEffect(()=>{
		serviceApi.post("catalogo/nomenclatura").then((response)=>{
			setNomenclatura(response.data.data.nomenclatura);
		});
		//getEquipos();
    
    
    
   
	}, []);
  useEffect(()=>{
    getEquipos();
  },[filtroDepto,filtroMuni,latlng,tipo_servicio,usarGeo]);

  const regresar = ()=>{
    navigate("/home/"+tipo);
  }
 
  function abrir_modal_confirmacion(){
    setModal({
      show:true,
      error:false,
      message:"Activa tu GPS para poder darte información de tus puntos cercanos a tu ubicación actual",
      setModal:setModal
    });

  }
  function confirmarGPS(){
    usar_ubicacion();
  }
  function cancelGPS(){
    setUsarGeo(false);
   
  }
  async function usar_ubicacion (){

    if (navigator.geolocation) {

      navigator.geolocation.getCurrentPosition((pos)=>{
        console.log("true");
        setLatlan({
          lat:pos.coords.latitude,
          lng:pos.coords.longitude,
        });
        setUsarGeo(true);
      },()=>{
        console.log("error GPS");
      });

    } else {
      alert( "Geolocation is not supported by this browser.");
    }
  }
  const distancia_format=(val)=>{
    let km = parseFloat(val);
    let unit = "Km";
    if(km<1){
      km = km*1000;
      unit= "mts";
    }
    return km.toFixed((unit=="Km"?1:0))+" "+unit;
  };
  function buscarAtm(tipo){
    navigate("/equipo-servicio/atm/"+tipo);
  }
  return (
    <div className="container p-0 m-0">
      <Header showBack={true} callback={regresar} />

      <div className="container-select-2 mt-3">
        <div className="container-txt-select">
          <p className="txt-select">Departamento:</p>
        </div>
        <div className="container-option">
        <SelectNomemclatura lista={nomenclatura} tipo="D" className="select-departamento-2" value={filtroDepto} 
        onChange={(e)=>{ setFiltroDepto(e.target.value);setFiltroMuni(0);}} ></SelectNomemclatura>
        </div>
      </div>

      <div className="container-select-2 mt-3">
        <div className="container-txt-select">
          <p className="txt-select">Municipio:</p>
        </div>
        <div className="container-option">
        <SelectNomemclatura lista={nomenclatura} tipo="M" className="select-departamento-2" parent={filtroDepto} value={filtroMuni} onChange={(e)=>{ setFiltroMuni(e.target.value);}} ></SelectNomemclatura>
        </div>
      </div>

      <div className="d-flex flex-row mt-4 container-location">
        <div className="d-flex flex-row align-items-center" onClick={abrir_modal_confirmacion}>
          <img className="location" src={location} alt="location" />
          <p className="txt-ubication">Usar mi ubicación</p>
        </div>
        {
          tipo=='atm' && (
            <div className="tab-panel">
            <span className={tipo_servicio!="depositario"?"active":""} onClick={(e)=>buscarAtm("dispensador")}>Dispensadores</span>
            <span className={tipo_servicio=="depositario" ? "active":""} onClick={(e)=>buscarAtm("depositario")}>Depositarios</span>
          </div>
          ) 
        }
        
        {/*tipo=='atm'?
        <div className="div-depositario">
          <p className="txt-depositario">{tipo_servicio=='depositario'?'DEPOSITARIOS':'DISPENSADORES'} EN SEVICIO</p>
        </div>
      :''*/}
      </div>
      <div className="lista-equipos">
        {equipos.map(item=>(
          <div className="Container-card-info mt-4">
            <div className="d-flex flex-row justify-content-between">
              <p className="txt-title-ubi">{item.codigo+" - "+item.nombre}</p>
              {item.distancia!=undefined?<p className="txt-title-ubi">{distancia_format(item.distancia)}</p>:""}
            </div>
            
            <p className="txt-content-ubi">
            {item.ubicacion}
            </p>
            <p className="txt-content-ubi mb-2">
              Horario: L-V: {item.horario_lv} | Sábado: {item.horario_s} | Domingo: {item.horario_d}
            </p>
          </div>
        ))}
        {equipos.length==0?<div className="text-center mt-5"><h4>Sin resultados</h4></div>:""}
      </div>
      <div className="mt-2 container-info">
        <img className="graphic" src={info} alt="graphic" />
        <p className="txt-info-bottom">Información en Línea</p>
      </div>
      <AlertModal show={modal.show} message={modal.message} setShow={modal.setModal} error={modal.error}  hideBtnClose={true} useYesNot={true} callbackYes={confirmarGPS} callbackNot={cancelGPS}  ></AlertModal>
        
          
    </div>
  );
};

export default EquipoServicio;

import React, { useState, useContext,useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./assets/login.css";
import logo from "./assets/img/logo.svg";
import iconUser from "./assets/img/icon-user.svg";
import iconPassword from "./assets/img/icon-pass.svg";


import  serviceApi from "../../services/services";
import AlertModal from "../alertModal/AlertModal";

import { useNavigate  } from "react-router-dom";
import {AuthContext,setSession,getSession} from "../../auth/AuthContext";
 

const Login = (props) => {
  const navigate  = useNavigate();
 
  useEffect(()=>{
    if(props.logout){
      setSession(null);
    }
    console.log("SES",getSession());
    if(getSession()!=undefined){
      navigate("/home");
    }
  },[]);
 

  // ocultar y mostrar contraseña
  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");

  const [formData,setFormData] = useState({usuario:"",clave:""});
  const [modal,setModal] = useState({show:false,error:false,message:"",setModal:null});
  const [sending,setSending] = useState(false);
  const [mensaje,setMensaje] = useState({});
  const [mensajeBloqueo,setMensajeBloqueo] = useState({});
  const [isLogged,setIsLogged] = useState(false);
  const [cambioClave,setCambioClave] = useState(false);

  const handleUsuarioChange = (evnt)=>{

    setFormData({...formData,usuario:evnt.target.value});
  }
  const handlePasswordChange = (evnt) => {

    setFormData({...formData,clave:evnt.target.value});
  };
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  document.body.classList.add("remove-bg");
  const submit=(e)=>{
    e.preventDefault();
    setMensaje({});
    setMensajeBloqueo({});
    setSending(true);

    serviceApi.post("usuario/login",formData).then(function(response){
      if(response.data.code!=200){
        
        if(response.data.data && response.data.data.usuario_bloqueado==1){
          setMensajeBloqueo({
            show:true,
            message:response.data.msj
          });
        }else{
          setMensaje({
            show:true,
            message:response.data.msj
          });
        }
        

      }else{
        setSession(response.data.data);
         //localStorage.setItem("WPAUSER",JSON.stringify(response.data.data));
        setCambioClave(response.data.data.cambio_clave=="1"?true:false);
        setIsLogged(true);
       
       
      }
      setSending(false);
    },function(response){
   
      setMensaje({
        show:true,
        message:"Error, intente más tarde"
      });
      setSending(false);
    });
    return false;
  }
useEffect(()=>{
  if(isLogged){
 
    if(cambioClave){
      //navigate("/cambiar-clave");
      window.location.href="/cambiar-clave";
    }else{
      window.location.href="/home";
      //navigate("/home");
    }
  }
  
  
},[isLogged]);
  return (
    <div className="login-body p-0 m-0">
      <div className="d-flex flex-column justify-content-center align-items-center mt-4 login-header">
        <img className="" src={logo} alt="logo" />
        <p className="txt-login mt-2">
          Bienvenido a la aplicación de Sistema de gestión ATMs y Kioskos
        </p>
      </div>
      <form action="" onSubmit={submit}>
        <div className="container-inputs mt-5">
          <div className="container-input-title">
            <img className="" src={iconUser} alt="icon-user" />
            <p className="txt-user">Usuario</p>
          </div>
          <input className="input-user w-100" value={formData.usuario} type="text" required onChange={handleUsuarioChange} />
          {mensajeBloqueo.show?(<label className="lbl-error text-left">{mensajeBloqueo.message}</label>):""}
        </div>
        <div className="container-inputs mt-4">
          <div className="container-input-title">
            <img className="" src={iconPassword} alt="icon-user" />
            <p className="txt-user">Clave</p>
          </div>
          <input
            className="input-user w-100"
            type={passwordType}
            onChange={handlePasswordChange}
            value={formData.clave}
            required
          />
          {formData.clave!="" && (
            <button className="btn-show-pass" type="button" onClick={togglePassword}>
            {passwordType === "password" ? (
              <i className="fa-light fa-eye"></i>
            ) : (
              <i className="fa-light fa-eye-slash"></i>
            )}
          </button>
          )}
          

          <div className="d-flex flex-column justify-content-center align-items-center mt-2">
            <NavLink className="link-forgot-pass" to="/forgot">
              ¿Olvidaste o bloqueaste tu usuario o clave?
            </NavLink>
          </div>
         
          <div className="d-flex flex-column justify-content-center align-items-center mt-5">
            <button className={"btn-login "+(sending && "btn-loading ")} disabled={sending}>Continuar</button>
          </div>
          {mensaje.show?(<label className="lbl-error">{mensaje.message}</label>):""}
        </div>
      </form>

      <div className="row m-0 w-100 px-3 mt-5">
        <div className="col-6">
          <NavLink className="link-bottom" to="/register">
            Regístrate
          </NavLink>
        </div>
        <div className="col-6 d-flex justify-content-end">
          <NavLink className="link-bottom" to="/forgot/user">
            Olvidé mi usuario
          </NavLink>
        </div>
      </div>
      <AlertModal show={modal.show} setShow={modal.setModal} error={modal.error} message={modal.message}></AlertModal>
    </div>
  );
};

export default Login;

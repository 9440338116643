import React, { useState, useEffect } from "react";
import "./assets/fallasActivas.css";
import backgroundImage from "./assets/img/background.png";
import Mapa from "../mapa/mapa";
import serviceApi from "../../services/services";
import Header from "../header/header";

import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import SelectNomemclatura from "../selectNomemclatura/selectNomemclatura";
import { useNavigate, useParams } from "react-router-dom";

const FallasActivas = () => {
  let { tipo } = useParams();
  const [departamento, setDepartamento] = useState(0);
  const [totales, setTotales] = useState([]);
  const [datosMapa, setDatosMapa] = useState([]);
  const [chartOptions, setChartOptions] = useState({});
  const [nomenclatura, setNomenclatura] = useState([]);
  const [tipoGrafica, setTipoGrafica] = useState("cuantitativa");


  const navigate = useNavigate();

  const verDetalles = (e) => {
    let departamento = e.target.value;
    setDepartamento(departamento);
    //this.history.pushState(null, "/fallas-lista/atm/san-salvador"+departamento);
    navigate("/fallas-lista/" + tipo + "/" + departamento);
  };

  const getTotalFallasDepartamento = () => {
    serviceApi.post("equipo/totales_departamento/" + (tipo == "atm" ? 1 : 2)).then((response) => {
      if (response.data.code === 200) {
        let _totales = response.data.data.totales;

        let datosMapa = [];
        _totales.forEach((item) => {
          datosMapa.push({
            id_depto: item.id_depto,
            depto: item.depto,
            cant: item.fallas,
            alert1: item.dispensador,
            alert2: item.depositario,
            key: item.depto,
            tipo: tipo
          });
        });
        //console.log(datosMapa)
        setDatosMapa(datosMapa);
        setTotales(_totales);
      }
    })
  };
  useEffect(() => {
    construir_grafica();
  }, [totales, tipoGrafica]);
  function construir_grafica() {
    let tipo_grafica = "column";
    let series = [];
    let chart_h = 350;
    let stacking = "percent";
    if (tipoGrafica == "cuantitativa") {
      tipo_grafica = "column";
      series = [
        {
          name: "Total de Equipos en Servicio",
          data: totales.map((x) => Number(x.total_equipos_disponibles)),
          color: '#00CCEA'
        },

        {
          name: "Fallas Activas",
          data: totales.map((x) => Number(x.fallas)),
          color: '#F38645'

        },
      ];
    } else {
      tipo_grafica = "bar";
      chart_h = totales.length * 90;
      stacking = false;
      series = [

        {
          name: "% Disponibilidad de servicio",
          data: totales.map((x) => Number(x.disponibilidadQ)),
          color: '#0BA783'
        },

        {
          name: "% Fallas sobre el total de equipos",
          data: totales.map((x) => Number(x.fallas_porcentaje)),
          color: '#9063CD'

        },
      ];
    }


    let chartOptions = {
      chart: {
        type: tipo_grafica,
        height: chart_h,
      },
      credits: {
        enabled: false
      },
      title: { text: null },
      xAxis: {
        categories: totales.map((x) => x.departamento),
        title: { text: null, align: 'high' }
      },
      yAxis: {
        showAxis: false,
        min: 0,
        title: {
          text: '',//'Cantidad',
        },
        labels: {
          overflow: 'justify',
          enabled: true
        }
      },
      series: series,
      plotOptions: {
        column: {
          dataLabels: {
            crop: false,
            enabled: true,
            grouping: false,
            shadow: false,
            borderWidth: 0,
            formatter: function () {

              return this.y
            }
          }
        },
        bar: {
          dataLabels: {
            crop: false,
            enabled: true,
            grouping: true,
            shadow: false,
            borderWidth: 0,
            formatter: function () {
              return this.y + "%"
            }
          }
        },
        series: {
          stacking: stacking,
        }
      },
      tooltip: {
        shared: false,
        formatter: function () {
          return tipo_grafica == "bar" ? (this.y) + "%" : this.y
        }
      }
    };
    setChartOptions(chartOptions);
  }


  const callback_mapa = (depto,tipo_servicio) => {
    let extra="";
   
    if(tipo=='atm'){
      extra=`/sinservicio/${tipo_servicio}`;
    }
    navigate("/fallas-lista/" + tipo + "/" + depto+extra);
  }

  useEffect(() => {
    getTotalFallasDepartamento();
    serviceApi.post("catalogo/nomenclatura").then((response) => {
      setNomenclatura(response.data.data.nomenclatura);
    });
  }, [])


  return (
    <div className="container p-0 m-0">
      <Header showBack={true} callback={() => navigate("/home/" + tipo)} />

      <div className="container-select mt-3">
        <p className="txt-select">Departamento:</p>
        <SelectNomemclatura lista={nomenclatura} tipo="D" className="select-departamento" onChange={verDetalles} value={departamento}></SelectNomemclatura>

      </div>

      <div className="container-selections mt-3">

        <div className="active-content">
          <div className="mt-4 container-map">
            <Mapa data={datosMapa} callback={callback_mapa} />
          </div>

          <div className="mt-4 border-div-title">
            <p className="txt-title-graphic">CONSOLIDADO DE FALLAS {tipo == "atm" ? "(DISPENSADOR + DEPOSITARIO)" : "KIOSKOS"}</p>
            <div className="container-select mt-2 mb-2">
              <p className="txt-select">Tipo:</p>
              <select name="" id="" className="select-departamento" style={{ width: "80%" }}
                value={tipoGrafica} onChange={(e) => { setTipoGrafica(e.target.value) }}>
                <option value="cuantitativa">Disponibilidad Cuantitativa</option>
                <option value="porcentual">Disponibilidad Porcentual</option>
              </select>

            </div>


          </div>

          <div className="grafica-container">
            <HighchartsReact
              highcharts={Highcharts}
              options={chartOptions}
            />
          </div>
        </div>

      </div>




    </div>
  );
};

export default FallasActivas;

import React, { useState, useEffect } from "react";
import $ from 'jquery';
import "./assets/mapa.css";
import { ReactComponent as MapaSVG } from "./assets/img/mapa.svg";



const Mapa = (props) => {

	const [bullets,setBullets] = useState([]);//{depto:'SS',cant:10},{depto:'SM',cant:5}
	const [alert,setAlert] = useState({});
	$(".svg-container svg #departamentos path").off("click");
	$(".svg-container svg #departamentos path").click((e)=>{
		//console.log(e.target.id);
		let depto = e.target.id.replace("SV-","");
		let dataAlert = bullets.find(x=>x.key==depto);
		console.log(dataAlert);
		if(dataAlert){
			setAlert({
				depto:dataAlert.id_depto,
				alert1:Number(dataAlert.alert1),
				alert2:Number(dataAlert.alert2),
				alert_k:dataAlert.tipo=='kiosko'?Number(dataAlert.cant):0
			});
		}else{
			setAlert({});
		}
	});
	$(window).resize(()=>{
		let w = $(window).width();
		
		let per = w/500;
		if(w<500){
			$(".svg-container").css("zoom",per);
		}else{
			$(".svg-container").css("zoom",1);
		}
	});

	//setBullets(data);
	
	useEffect(()=>{
		setBullets(props.data);
		$(window).resize();
		//console.log(props.data)
	  })

	return (
		<>
		
		<div className="svg-container">
			<div className="mapa-alertas"  >
				{(alert.alert1>0?<div className="box-alert"  onClick={(e)=>(props.callback(alert.depto,'dispensador')??null)}>{alert.alert1} ATMs DISPENSADOR</div>:"")}
				{(alert.alert2>0?<div className="box-alert"  onClick={(e)=>(props.callback(alert.depto,'depositario')??null)}>{alert.alert2} ATMs DEPOSITARIO</div>:"")}
				{(alert.alert_k>0?<div className="box-alert"  onClick={(e)=>(props.callback(alert.depto)??null)}>{alert.alert_k} Kioskos</div>:"")}
			</div>
            <MapaSVG />
			{
				(bullets.length)?
				bullets.map((item)=>
					(<span key={item.depto} className={"bullet-falla pos-"+item.depto}>{item.cant}</span>)
				)
				:""
			}	
       </div>
		</>
	);
}
export default Mapa;
import React, { useState,useEffect} from "react";

import { Modal, Button } from "react-bootstrap";
import "./AlertModal.css";
import iconError from "./error.png";
function AlertModal(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    
    if(typeof props.callback=="function"){
      props.callback();
    }
    props.setShow(false);
    
  };
 


  async function trueBtn(){
    if(props.callbackYes){
      props.callbackYes();
    }
    props.setShow(false);
  }
  async function falseBtn(){ 
    if(props.callbackNot){
      props.callbackNot();
    }
    props.setShow(false);
  }
  return (
    <>
      <Modal
        show={props.show}
        onHide={handleClose}
        centered
        className={"modal-alert " + (props.error ? "error" : "")}
      >
        {props.error ? (
          <div className="bar-error">
            <img src={iconError} alt="error" />
          </div>
        ) : null}
        <Modal.Body>{props.message}</Modal.Body>
        <Modal.Footer className="justify-content-center">
          {!props.hideBtnClose && (<Button variant="primary" onClick={handleClose}>
            Cerrar
          </Button>)}
          {props.useYesNot && (
            <div className="row">
            <div className="col-6">
              <Button  onClick={trueBtn} style={{width:"70px"}}>Si</Button>
            </div>
            <div className="col-6">
              <Button onClick={falseBtn} style={{width:"70px"}}>No</Button>
            </div>
          </div>
          )}
          
          
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default AlertModal;

import axios from "axios";
import {getSession} from "../auth/AuthContext";
//colocar host de la api
//const urlBase = "http://192.168.1.117:8080/api/wpacliente";
const urlBase = "https://app.agricolamovil.com/backofficeBA/api/wpacliente";
/**
 * @param {string}  url url a la cual consultar
 * esta funcion detecta si es una nueva url base (comienza con http:// o https://).
 * en caso de ser asi, retorna la url. en caso contrario, se asume que es un fragmento
 * de path por lo que se concatena con la constante urlBase
 **/
const readUrl = (url = "") =>
  url.startsWith("http://") || url.startsWith("https://")
    ? url
    : `${urlBase}/${url}`;

const get = (url = "",params={}, headers = {}) =>{
    if(getSession()){
      headers.Authorization = "Bearer "+getSession().token;
    }
  return axios.get(readUrl(url), {
    params:{...params},
    headers: {
      Accept: "application/json; charset=UTF-8",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",

      ...headers,
    },
  })};

const post = (url = "", body = {}, headers = {}) =>{
  if(getSession()){
    headers.Authorization = "Bearer "+getSession().token;
  }
  return axios.post(readUrl(url), body, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      //"Access-Control-Allow-Origin": "*",

      ...headers,
    },
  })};

const postUpload = (url = "", body = {}, headers = {}) =>
  axios.post(readUrl(url), body, {
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      //"Access-Control-Allow-Origin": "*",

      ...headers,
    },
  });

const put = (url = "", body = {}, headers = {}) =>
  axios.put(readUrl(url), body, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      //"Access-Control-Allow-Origin": "*",

      ...headers,
    },
  });

const del = (url = "", headers = {}) =>
  axios.delete(readUrl(url), {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      //"Access-Control-Allow-Origin": "*",

      ...headers,
    },
  });

const serviceApi = {
  get,
  post,
  put,
  postUpload,
  delete: del,
};

export default serviceApi;
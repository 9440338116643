import React, { useState, useEffect, useReducer } from "react";
import App from "./routers/App";
import { AuthContext, setSession, getSession } from "./auth/AuthContext";

import "./index.css";
import "../src/FontAwesome/css/all.css";

import Splash from "./components/splash/Splash";

const init = () => {

};

const MyApp = () => {


  let time_limit = 180 * 1000;
  let timer1 = null;
  const [user, setSession] = useState(getSession());

  useEffect(() => {
    setSession(user);
  }, [user]);

  //const [timer1,setTimer1]=useState(time_limit);
  useEffect(() => {
    updateTimer();
    return () => {
      clearTimeout(timer1);
    };
  }, [])

  function updateTimer() {

    clearTimeout(timer1);

    timer1 = setTimeout(function () {
      if (window.location.pathname != "/logout" && window.location.pathname != "/")
        window.location.href = "/logout";
    }, time_limit);
  }
  return (
    <AuthContext.Provider value={{ user }}>
      <div className="container-wpa" onClick={updateTimer}>
        <App />
      </div>
      <Splash />

    </AuthContext.Provider>
  );
};

export default MyApp;

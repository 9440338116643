import React, { useState } from "react";
import "./assets/buscarCajeros.css";
import Navbar from "../Navbar/Navbar";
import logo from "./assets/img/logo.png";
import backgroundImage from "./assets/img/background.png";
import info from "./assets/img/Info.svg";
import location from "./assets/img/location.png";

const BuscarCajeros = () => {
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  return (
    <div className="container p-0 m-0">
      <div className="d-flex flex-column justify-content-center align-items-center mt-4 login-header">
        <img className="" src={logo} alt="logo" />
      </div>
      <Navbar />
      <div className="d-flex justify-content-center">
        <p className="date-app">Viernes 24 de septiembre de 2021-11:30 am</p>
      </div>

      <div className="container-select-2 mt-3">
        <div className="container-txt-select">
          <p className="txt-select">Departamento:</p>
        </div>
        <div className="container-option">
          <select className="select-departamento-2">
            <option value="1">Ahuachapán</option>
            <option value="2">San Miguel</option>
            <option value="3">San Salvador</option>
            <option value="4">Santa Ana</option>
            <option value="5">Sonsonate</option>
            <option value="6">Usulután</option>
            <option value="7">La Libertad</option>
            <option value="8">La Paz</option>
            <option value="9">Cabañas</option>
            <option value="10">Chalatenango</option>
            <option value="11">Cuscatlán</option>
            <option value="12">Morazán</option>
            <option value="13">La Unión</option>
            <option value="14">San Vicente</option>
          </select>
        </div>
      </div>

      <div className="container-select-2 mt-3">
        <div className="container-txt-select">
          <p className="txt-select">Municipio:</p>
        </div>
        <div className="container-option">
          <select className="select-departamento-2">
            <option value="1">Ahuachapán</option>
            <option value="2">San Miguel</option>
            <option value="3">San Salvador</option>
            <option value="4">Santa Ana</option>
            <option value="5">Sonsonate</option>
            <option value="6">Usulután</option>
            <option value="7">La Libertad</option>
            <option value="8">La Paz</option>
            <option value="9">Cabañas</option>
            <option value="10">Chalatenango</option>
            <option value="11">Cuscatlán</option>
            <option value="12">Morazán</option>
            <option value="13">La Unión</option>
            <option value="14">San Vicente</option>
          </select>
        </div>
      </div>

      <div className="mt-4 d-flex flex-row px-4">
        <div
          className={
            toggleState === 3
              ? "container-tab-2 active-tabs-2"
              : "container-tab-2"
          }
          onClick={() => toggleTab(3)}
        >
          <img className="location-2" src={location} alt="location" />
          <p className="txt-tabs-2">Usar mi ubicación</p>
        </div>
        <div
          className={
            toggleState === 1
              ? "container-tab-2 active-tabs-2"
              : "container-tab-2"
          }
          onClick={() => toggleTab(1)}
        >
          <p className="txt-tabs-2">Dispensadores</p>
        </div>
        <div
          className={
            toggleState === 2
              ? "container-tab-2 active-tabs-2"
              : "container-tab-2"
          }
          onClick={() => toggleTab(2)}
        >
          <p className="txt-tabs-2">Depositarios</p>
        </div>
      </div>

      <div
        className={
          toggleState === 1 ? "Content-tab active-content" : "Content-tab"
        }
      >
        <div className="Container-card-info mt-4">
          <div className="d-flex flex-row justify-content-between">
            <p className="txt-title-ubi-2">ISSS Zacamil</p>
            <p className="txt-title-ubi-2">1.1 Km</p>
          </div>

          <p className="txt-content-ubi">
            Colonia Zacamil, contiguo a ex cine zacamil, frente al INAM
          </p>
          <p className="txt-content-ubi mb-2">
            Horarios: L-V: Abierto 24 horas | Sábado: Abierto 24 horas |
            Domingo: Abierto 24 horas
          </p>
        </div>
      </div>

      <div
        className={
          toggleState === 2 ? "Content-tab active-content" : "Content-tab"
        }
      >
        <div className="Container-card-info mt-4">
          <div className="d-flex flex-row justify-content-between">
            <p className="txt-title-ubi-2">CENTRO COMERCIAL ZACAMIL</p>
            <p className="txt-title-ubi-2">1.2 Km</p>
          </div>

          <p className="txt-content-ubi">
            29 Avenida Norte, Centro Comercial Zacamil, Colonia Zacamil, San
            Salvador.
          </p>
          <p className="txt-content-ubi mb-2">
            Horario: L-V: 07:00-22:00 | Sábado: 07:00-20:00 | Domingo:
            07:00-20:00
          </p>
        </div>
      </div>

      <div className="mt-2 container-info">
        <img className="graphic" src={info} alt="graphic" />
        <p className="txt-info-bottom">Información en Línea</p>
      </div>
    </div>
  );
};

export default BuscarCajeros;

import React, { useState,useEffect } from "react";
import  serviceApi from "../../services/services";
 
const SelectNomemclatura = (props)=>{
	const [lista,setLista] = useState([]);

	useEffect(()=>{
	
		setLista(props.lista);
	  }, [props])

	return(
		<select className={props.className} onChange={(e)=>(props.onChange?props.onChange(e):"")} value={props.value}>
			<option value="0">Todos</option>
			{lista.map((item)=>
				(item.tipo==props.tipo && (props.parent==undefined || props.parent==item.id_padre)?
					<option value={item.id}>{item.descripcion}</option>
					:"")
			)}
		</select>
	);
}
export default SelectNomemclatura;
import React from "react";
import "./assets/historico.css";
import Navbar from "../Navbar/Navbar";
import logo from "./assets/img/logo.png";
import backgroundImage from "./assets/img/background.png";
import tuerca from "./assets/img/tuerca.png";
import cicle from "./assets/img/repeticion.png";
import calendary from "./assets/img/calendario.png";
import info from "./assets/img/Info.svg";

const Historico = () => {
  return (
    <div className="container p-0 m-0">
      <div className="d-flex flex-column justify-content-center align-items-center mt-4 login-header">
        <img className="" src={logo} alt="logo" />
      </div>
      <Navbar />
      <div className="d-flex justify-content-center">
        <p className="date-app">Viernes 24 de septiembre de 2021-11:30 am</p>
      </div>

      <div className="mt-2 d-flex justify-content-center">
        <p className="txt-historico">Histórico de fallas</p>
      </div>

      <div className="Container-card-info mt-2">
        <div className="d-flex flex-row justify-content-between mt-2">
          <div className="container-card-falla d-flex flex-row">
            <img className="img-tuerca" src={tuerca} alt="tuerca" />
            <p className="txt-falla">Falla</p>
          </div>
          <div className="container-card-falla-2">
            <p className="txt-falla-2">: Dispensador general</p>
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between mt-1">
          <div className="container-card-falla d-flex flex-row">
            <img className="img-tuerca" src={cicle} alt="tuerca" />
            <p className="txt-falla">Tiempo de duración</p>
          </div>
          <div className="container-card-falla-2 ">
            <p className="txt-falla-2">: 1 hora</p>
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between mt-1">
          <div className="container-card-falla d-flex flex-row">
            <img className="img-tuerca" src={calendary} alt="tuerca" />
            <p className="txt-falla">Fecha</p>
          </div>
          <div className="container-card-falla-2 ">
            <p className="txt-falla-2">: 10/09/2021</p>
          </div>
        </div>
      </div>

      <div className="mt-2 container-info">
        <img className="graphic" src={info} alt="graphic" />
        <p className="txt-info-bottom">Información en Línea</p>
      </div>
    </div>
  );
};

export default Historico;
